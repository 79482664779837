<template>
  <div class="promo-card-skeleton">
    <div class="promo-card-skeleton__image animation" />
    <div class="promo-card-skeleton__content">
      <div class="promo-card-skeleton__container">
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
      </div>
      <div class="promo-card-skeleton__container">
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
          <div class="promo-card-skeleton__block__item__desc animation" />
        </div>
      </div>
      <div class="promo-card-skeleton__container">
        <div class="promo-card-skeleton__block">
          <div class="promo-card-skeleton__block__item__title animation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
.promo-card-skeleton {
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  padding: 10px 24px;
  border-radius: 2px;
  height: 220px;
  display: flex;
  gap: 30px;
  &__image {
    width: 300px;
    height: 100%;
    border-radius: 10px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &__item {
      height: 25px;
      border-radius: 5px;
      &__title {
        width: 100px;
        @extend .promo-card-skeleton__block__item;
      }
      &__desc {
        width: 200px;
        @extend .promo-card-skeleton__block__item;
      }
    }
  }
}
.animation {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
</style>
